import type { Context } from '@nuxt/types';

import getAuthApi from '~/services/auth';
import getPushApi from '~/services/push';
import getSearchApi from '~/services/search';
import getTalkerApi from '~/services/talker';
import getWordpressApi from '~/services/wordpress/wordpressApi';
import getSpecialApi from '~/services/special';
import getViewsApi from '~/services/views';
import getReactionsApi from '~/services/reactions/reactions';
import getQuizApi from '~/services/quiz';
import getSportsApi from '~/services/sports';
import getIronMaidenApi from '~/services/ironMaiden';
import getMascotApi from '~/services/mascot';

import { createHealthApi } from '~/services/health';

import { getVersionFromQuery } from '~/utils';
import apiGateway from '~/services/api-gateway';

export default function (context: Context, inject: any): any {
  const { $axios, $logger, route, $sentry, store, redirect, app } = context;
  const version = getVersionFromQuery(route.query as RouteQuery);

  inject('wordpressApi', getWordpressApi({ $axios, $logger, $sentry, version }));
  inject('ironMaidenApi', getIronMaidenApi({ $axios, $logger, $sentry, version }));
  inject('authApi', getAuthApi({ $axios, $logger, $sentry, store, redirect, route }));
  inject('pushApi', getPushApi({ $axios, $sentry, $logger }));
  inject('searchApi', getSearchApi({ $axios, $sentry, $logger }));
  inject('commentsApi', getTalkerApi({ $axios, $sentry, $logger }));
  inject('specialApi', getSpecialApi({ $axios, $sentry, $logger }));
  inject('viewsApi', getViewsApi({ $axios, $logger, $sentry }));
  inject('reactionsApi', getReactionsApi({ $axios, $sentry, $logger, store, redirect, route }));
  inject('quizApi', getQuizApi({ $axios, $sentry, $logger }));
  inject('sportsApi', getSportsApi({ $axios, $logger }));
  inject('apiGateway', apiGateway({ $axios, $logger, $sentry, app, redirect }));
  inject('mascotApi', getMascotApi({ $axios, $sentry, $logger }));
  inject('healthApi', createHealthApi(context));
}
